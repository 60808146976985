<template>
    <section class="">
        <div class="mb-4">
            <h2 class="text-4xl text-[#2b2b2b]">Requêtes</h2>
        </div>
        <div class="">

            <!-- CARDS -->
            <div class="row clearfix">
                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div class="info-box-3 bg-blak hover-zoom-effect">
                        <div class="icon">
                            <i class="material-icons">repeat</i>
                        </div>
                        <div class="content">
                            <h2 class="text">REQUÊTES</h2>
                            <div class="number">{{ data.requests }}</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div class="info-box-3 bg-blue-grey hover-zoom-effect">
                        <div class="icon">
                            <i class="material-icons">help</i>
                        </div>
                        <div class="content">
                            <h2 class="text">EN ATTENTE DE VALIDATION</h2>
                            <div class="number">{{ data.encours }}</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div class="info-box-3 bg-blue-grey hover-zoom-effect">
                        <div class="icon">
                            <i class="material-icons">done_all</i>
                        </div>
                        <div class="content">
                            <h2 class="text">VALIDÉES</h2>
                            <div class="number">{{ data.validees }}</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div class="info-box-3 bg-blue-grey hover-zoom-effect">
                        <div class="icon">
                            <i class="material-icons">eject</i>
                        </div>
                        <div class="content">
                            <h2 class="text">REJETÉES</h2>
                            <div class="number">{{ data.rejetees }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- REGISTRE -->
            <div class="block-header">
                <h2>REGISTRE</h2>
            </div>
            <RequestTableComponent />



        </div>

        <!--  -->
        <div class="page-loader-wrapper" v-if="loader">
            <div class="loader">
                <div class="preloader">
                    <div class="spinner-layer pl-indigo">
                        <div class="circle-clipper left">
                            <div class="circle"></div>
                        </div>
                        <div class="circle-clipper right">
                            <div class="circle"></div>
                        </div>
                    </div>
                </div>
                <p>Chargement...</p>
            </div>
        </div>
    </section>
</template>

<script>
import RequestTableComponent from '@/components/tables/RequestTable'
import API from "../../service/API.js"


export default {
    name: 'RequestView',
    components: {
        RequestTableComponent
    },
    data() {
        return {
            loader: false,
            data: {},
        }
    },
    computed: {
        comp1() {
            return ""
        }
    },
    mounted() {
        this.getData()
    },
    created() {

    },
    methods: {

        getData() {
            this.loader = true
            API.get('/getRequestsDetails')
                .then((res) => {
                    this.data.requests = res.data.requests
                    this.data.encours = res.data.encours
                    this.data.validees = res.data.validees
                    this.data.rejetees = res.data.rejetees
                    this.loader = false

                })
                .catch((error) => {
                    console.error(error)
                    this.loader = false
                })
        },
    }
}

</script>