<template>
    <div class="w-full h-full text-black">
        <h2 class="text-[20px] font-normal">Sécurité</h2>
        <p class="font-extralight my-4">Gérer la sécurité de votre compte sur cette interface</p>
        <div class="w-full h-[1px] bg-[#eaeaea]"></div>
        <div class=" my-10 flex items-start">
            <div class="flex flex-col items-start gap-y-5">
                <h4 class="my-3">Mot de passe</h4>
                <p class="font-extralight">Appuyer sur "Edit" pour modifier le mot de passe</p>
            </div>
            <div class="grow ml-24 flex flex-col gap-y-8">
                <div class="flex items-center justify-between">
                    <div class="flex items-center gap-x-8">
                        <div class="h-[42px] w-[300px] rounded-lg border flex items-center justify-center">
                            <span v-if="!editMode" class="flex items-center gap-x-1">
                                <div class="bg-black h-[6px] w-[6px] rounded-full"></div>
                                <div class="bg-black h-[6px] w-[6px] rounded-full"></div>
                                <div class="bg-black h-[6px] w-[6px] rounded-full"></div>
                                <div class="bg-black h-[6px] w-[6px] rounded-full"></div>
                                <div class="bg-black h-[6px] w-[6px] rounded-full"></div>
                                <div class="bg-black h-[6px] w-[6px] rounded-full"></div>
                                <div class="bg-black h-[6px] w-[6px] rounded-full"></div>
                                <div class="bg-black h-[6px] w-[6px] rounded-full"></div>
                                <div class="bg-black h-[6px] w-[6px] rounded-full"></div>
                                <div class="bg-black h-[6px] w-[6px] rounded-full"></div>
                                <div class="bg-black h-[6px] w-[6px] rounded-full"></div>
                                <div class="bg-black h-[6px] w-[6px] rounded-full"></div>
                            </span>
                            <input v-if="editMode" v-model="formInfo.old_password" type="text" name="password" class="w-full h-full p-4 border-none outline-none rounded-lg" placeholder="Ancien mot de passe">
                        </div>
                        <span v-if="!editMode" class="text-[#ff4b4b]">Niveau faible</span>
                    </div>
                    <button v-on:click="swithToEditMode" :disabled="asSend" class="px-8 py-3 rounded-lg border">Edit</button>
                </div>
                <div v-if="editMode" class="h-[42px] w-[300px] rounded-lg border">
                    <input type="text" name="password" v-model="formInfo.new_password" class="w-full h-full p-4 border-none outline-none rounded-lg" placeholder="Nouveau mot de passe">
                </div>
                <button v-if="editMode" :disabled="asSend" class="py-3 bg-[#5357eb] text-white w-[300px] rounded-lg">
                    <Loader2 v-if="asSend" class="animate-spin mx-auto"/>
                    <span v-if="!asSend">Enregistrer</span>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import Auth from "../../../service/Auth.js";
import {Loader2} from "lucide-vue-next"
export default {
    name: "SecurityVue",
    components:{
        Loader2
    },
    data(){
        return{
            editMode:false,
            formInfo:{
                old_password:null,
                new_password:null
            },
            asSend:false
        }
    },
    methods:{
        swithToEditMode(){
            this.editMode = !this.editMode;
        },
        updatePassword(){
            Auth.updatePassword(this.formInfo,this.switchAsSend)
        },
        switchAsSend(){
            this.asSend = !this.asSend;
        }
    }
}
</script>