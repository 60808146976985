<template>
    <div class="mb-4 flex items-center justify-between">
            <h2 class="text-4xl text-[#2b2b2b]">Mes Exceptions</h2>
    </div>
    <div class="bg-white rounded-lg p-4 mb-3">
        <div class="flex items-center justify-between">
            <div class="flex items-center gap-x-1 text-black font-light rounded-md">
                <span v-bind:class="getActiveClass('Retards')" v-on:click="selectBar('Retards')">Retards</span>
                <span v-bind:class="getActiveClass('Départ anticipé')" v-on:click="selectBar('Départ anticipé')">Départ anticipé</span>
                <span v-bind:class="getActiveClass('Absence')" v-on:click="selectBar('Absence')">Absence</span>
            </div>
            <div class="flex items-center gap-x-2">
                <input type="date" class="p-3 border rounded-md text-black cursor-pointer" v-model="date">
                <button :disabled="asSend" class="px-5 py-3 rounded-md border text-black" v-on:click="getAllUserException">
                    <Loader2 v-if="asSend" class="animate-spin mx-auto"/>
                    <span v-if="!asSend">Actualiser</span>
                </button>
            </div>
        </div>
    </div>
    <div class="p-4 rounded-lg bg-white">
        <h2 class="text-black text-[16px] font-light">{{ active }}</h2>
        <p class="mt-2 text-[#929292] font-extralight">
            Ensemble des {{ active }} enregistrés pour l'utilisateur connecté
        </p> 
        <div class="mt-6">
            <table v-if="!isload" class="w-full">
                <thead class="w-full">
                    <tr class="text-black flex justify-between w-full py-3">
                        <th class="font-extralight w-[14%] pl-[13px]">ID Exception</th>
                        <th class="font-extralight w-[28%] pl-[13px]">Date</th>
                        <th class="font-extralight w-[28%] pl-[13px]">Commentaire</th>
                        <th class="font-extralight w-[16%] pl-[13px]">Status</th>
                        <th class="font-extralight w-[14%] pl-[13px]">Action</th>
                    </tr>
                </thead>
                <tbody v-if="activeTab.length != 0" class="w-full flex flex-col gap-y-2">
                    <tr class="text-black bg-[#f9fafb] font-extralight rounded-lg flex items-center justify-between w-full py-3" v-for="abs in activeTab" :key="abs.id">
                        <td class="w-[14%] pl-[13px]">{{ abs["id"] }}</td>
                        <td class="w-[28%] pl-[13px]">{{ abs["created_at"] }}</td>
                        <td class="w-[28%] pl-[13px]">{{ abs["clock"]["comment"] ?? "Aucun commentaire" }}</td>
                        <td class="w-[16%] pl-[13px]">
                            <span class="py-2 px-3 rounded-2xl inline-flex items-center gap-x-2" :class="getStatusBgColors(abs['state'],abs['is_perm'])">
                                <div class="h-[6px] w-[6px] rounded-full" :class="getDotColors(abs['state'],abs['is_perm'])"></div>
                                <span :class="getStatusColors(abs['state'],abs['is_perm'])">{{ getStatus(abs["state"],abs["is_perm"]) }}</span>
                            </span>
                        </td>
                        <td class="w-[14%] pl-[18px]">
                            <JustifyException :id_exception="abs['id']" :id_type="abs['type_id']"/>
                        </td>
                    </tr>
                </tbody>
                <tr v-else class= "text-black bg-[#f9fafb] font-extralight rounded-lg flex justify-center w-full py-20">
                        Aucune donnée....
                </tr>
            </table>
        </div>
    </div>
</template>
<script>
import API from "../../service/API.js"
import {Loader2} from "lucide-vue-next";
import JustifyException from "@/components/create/justify-exception.vue";
export default{
    name:'MyException',
    components:{
        Loader2,
        JustifyException,
    },
    data(){
        return {
            asSend:false,
            activeTab:[],
            absence:[],
            departAnticipe:[],
            retard:[],
            isload : true,
            active:"Absence",
            date:""
        }
    },
    mounted(){
        this.getAllUserException();       
    },
    methods:{
        getAllUserException(){
            if(this.date){this.asSend = true;}
            const date = new Date();
            const years = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2,0);
            const day = date.getDate().toString().padStart(2,0);
            const id = localStorage.getItem("userId") ?? this.$store.getters.userId;
            const formData = new FormData();
            formData.append('date',this.date ? `${this.date.split("-")[0]}-${this.date.split("-")[1]}-01` : `${years}-${month}-${day}`);
            API.post("/getUserExceptions/"+id,formData)
            .then((data)=>{
                this.retard = data.data["data"]['r'];
                this.absence = data.data["data"]['a'];
                this.departAnticipe = data.data["data"]['d'];
                this.activeTab = this.active == "Absence" ? data.data["data"]['a'] : (this.active == "Retards" ? data.data["data"]['r'] : data.data["data"]['d']);
            })
            .catch((error)=>{
                console.error(error)
            })
            .finally(()=>{
                if(!this.date){this.isload = false;}
                else{this.asSend = false;}
            })
        },
        getActiveClass(libelle){
            return `px-4 py-3 rounded-md cursor-pointer ${libelle == this.active ? "bg-[#5357eb] text-white" : "bg-[#f9fafb] text-black"}`;
        },
        selectBar(libelle){
            this.active = libelle;
            this.activeTab = libelle == "Absence" ? this.absence : (libelle == "Retards" ? this.retard : this.departAnticipe)
        },
        getStatus(state,isperm){
            if(state == "0"){
                return isperm == 1 ? "Non approuvé" : "Non justifié"
            }else if(state == "1"){
                return "Attente"
            }else{
                return isperm == 1 ? "Approuvé": "Justifié"
            }
        },
        getStatusColors(state,isperm){
            if(state == "0"){
                return isperm == 1 ? "text-[#f25050]" : "text-[#f25050]"
            }else if(state == "1"){
                return "text-[#f2c450]"
            }else{
                return isperm == 1 ? "text-[#50f27b]": "text-[#f2c450]"
            }
        },
        getStatusBgColors(state,isperm){
            if(state == "0"){
                return isperm == 1 ? "bg-[#f7d8d8]" : "bg-[#f7d8d8]"
            }else if(state == "1"){
                return "bg-[#f7f6d8]"
            }else{
                return isperm == 1 ? "bg-[#d8f7de]": "bg-[#f7f6d8]"
            }
        },
        getDotColors(state,isperm){
            if(state == "0"){
                return isperm == 1 ? "bg-[#f25050]" : "bg-[#f25050]"
            }else if(state == "1"){
                return "bg-[#f2c450]"
            }else{
                return isperm == 1 ? "bg-[#50f27b]": "bg-[#f2c450]"
            }
        },
    }
}
</script>