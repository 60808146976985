<template>
    <button @click="$emit('validRequest',{id:id,cb:switchAsSend,action:'valid',motif:''})" :disabled="isSigned(valSup)" :class="getColor(isSup)" class="p-3 rounded-lg text-white w-[45%]">
        <Loader2 v-if="asSend" class="animate-spin mx-auto"/>
        <span v-if="!asSend">Valider</span>
    </button>
</template>
<script>
import {Loader2} from "lucide-vue-next";
export default {
    name: "ValidComponent",
    components:{
        Loader2
    },
    props:["valSup","id"],
    data(){
        return {
            asSend:false,
        }
    },
    methods:{
        switchAsSend(){
            this.asSend = !this.asSend;
        },
        isSigned(isSup){
          if(typeof isSup == "undefined") return false;
          return isSup == "1"
        },
        getColor(isSup){
            console.log("Couleur choisie : ",isSup == 1 ? "bg-[#6d6d6d]" : "bg-[#3fd777]")
            return isSup == "1" ? "bg-[#6d6d6d]" : "bg-[#3fd777]"
        }
    }
}
</script>