import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import VueBasicAlert from 'vue-basic-alert';
import './index.css';
import 'datatables.net-buttons-dt';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-buttons/js/buttons.print.mjs';

import VueHtmlToPaper from 'vue-html-to-paper';

axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')

createApp(App).use(store).use(router).use(VueBasicAlert).use(VueHtmlToPaper).mount('#app')
