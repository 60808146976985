<template>
    <div class="w-full h-full text-black">
        <h2 class="text-[20px] font-normal">Rapport</h2>
        <p class="font-extralight my-4">Visualiser le rapport de présence relatif à votre compte</p>
        <table v-if="!isload" class="w-full">
            <thead class="w-full">
                    <tr class="text-black flex justify-between w-full py-3">
                        <th class="font-extralight w-[15%] pl-[13px]">Clock Id</th>
                        <th class="font-extralight w-[35%] pl-[13px]">Arrivée</th>
                        <th class="font-extralight w-[35%] pl-[13px]">Départ</th>
                        <th class="font-extralight w-[15%] pl-[13px]">Zone Id</th>
                    </tr>
            </thead>
            <tbody v-if="presence.length != 0" class="w-full flex flex-col gap-y-2">
                <tr class="text-black bg-[#f9fafb] font-extralight rounded-lg flex items-center justify-between w-full py-3" v-for="pre in presence" :key="pre.id">
                    <td class="w-[15%] pl-[13px]">{{ pre["id"] }}</td>
                    <td class="w-[35%] pl-[13px]">{{ pre["hour_start"] }}</td>
                    <td class="w-[35%] pl-[13px]">{{ pre["hour_end"] }}</td>
                    <td class="w-[15%] pl-[13px]">{{ pre["zone_id"] }}</td>
                </tr>
            </tbody>
            <tr v-else class= "text-black bg-[#f9fafb] font-extralight rounded-lg flex justify-center w-full py-20">
                        Aucune donnée
            </tr>
        </table>
    </div>
</template>
<script>
export default{
    name:"ProfilRapport",
    props:["presence","isload"],
    components:{},
    methods:{}
}
</script>