export default{
    userId(state){
        return state.userId
    },
    supId(state){
        return state.supId
    },
    token(state){
        return state.token
    },
    profil(state){
        return state.profil
    },
    firstName(state){
        return state.firstName
    },
    lastName(state){
        return state.lastName
    },
    isAuthenticated(state){
        return state.token != null
    }
}