<template>
    <div class="mb-4 flex items-center justify-between">
            <h2 class="text-4xl text-[#2b2b2b]">Mes Demandes</h2>
    </div>
    <div class="bg-white rounded-lg p-4 mb-3">
        <div class="flex items-center justify-between">
            <div class="flex items-center gap-x-2 text-black font-light rounded-md">
                <span v-bind:class="getActiveClass('Congé')" v-on:click="selectBar('Congé')">Congé</span>
                <span v-bind:class="getActiveClass('Permission')" v-on:click="selectBar('Permission')">Permission</span>
            </div>
        </div>
    </div>
    <div class="bg-white rounded-lg p-4">
        <div v-if="getActiveTab('Congé')" class="w-full">
            <NewException permType="conge" title="Demande congé"/>
        </div>
        <div v-if="getActiveTab('Permission')" class="w-full">
            <NewException permType="permission" title="Demande de permission"/>
        </div>
    </div>
</template>
<script>
import NewException from "@/components/create/new-exception.vue";
export default{
    name:"MyDemande",
    components:{
        NewException
    },
    data(){return{
        active:"Congé",
    }},
    methods:{
        getActiveClass(libelle){
            return `px-4 py-3 rounded-md cursor-pointer ${libelle == this.active ? "bg-[#5357eb] text-white" : "bg-[#f9fafb] text-black"}`;
        },
        selectBar(libelle){
            this.active = libelle;
        },
        getActiveTab(libelle){
            return this.active == libelle
        },
    }
}
</script>