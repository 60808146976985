<template>
    <div class="login-page bg-indigo align.center">

        <div class="login-box bg-indigo">
            <div class="logo bg-indigo">
                <a href="javascript:void(0);" class="demo-choose-skin purple">Attendance <b>RH</b></a>
                <small>Système de gestion des présences LCE</small>
            </div>
            <div class="card">
                <div class="body">
                    <form id="sign_in" @submit.prevent="signin()">
                        <div class="msg">Commencez votre session</div>
                        <div class="input-group">
                            <span class="input-group-addon">
                                <i class="material-icons">person</i>
                            </span>
                            <div class="form-line">
                                <input type="text" class="form-control" name="username" placeholder="Nom d'utilisateur"
                                    v-model="credentials.identifier" required autofocus>
                            </div>
                        </div>
                        <div class="input-group">
                            <span class="input-group-addon">
                                <i class="material-icons">lock</i>
                            </span>
                            <div class="form-line">
                                <input type="password" class="form-control" name="password" placeholder="Mot de passe"
                                    v-model="credentials.password" required>
                            </div>
                        </div>

                        <div class="row m-t-15 m-b--20">
                            <div class="col-xs-12">
                                <button class="btn btn-block bg-indigo waves-effect align-center"
                                    style="display: flex;align-items: center;justify-content: space-between;">
                                    {{ loading ? 'CONNEXION...' : 'SE CONNECTER' }}
                                    <div v-if="loading == false">
                                        <i class="material-icons">trending_flat</i>
                                    </div>
                                    <div v-else class="preloader pl-size-xs">
                                        <div class="spinner-layer pl-white">
                                            <div class="circle-clipper left">
                                                <div class="circle"></div>
                                            </div>
                                            <div class="circle-clipper right">
                                                <div class="circle"></div>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
    <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
</template>

<script>
import Auth from "../../service/Auth.js";

export default {
    name: 'SigninView',
    data() {
        return {
            loading: false,
            credentials: { identifier: '', password: '' }
        }
    },
    methods: {
        switchLoader(){
            this.loading = !this.loading;
        },
        signin() {
            this.switchLoader()
            Auth.login(this.credentials,this.switchLoader);
        },
    },
}

</script>