<template>
    <div class="w-[70%] mx-auto mt-4">
            <canvas id="myChart"></canvas>
    </div>
</template>
<script>
import Chart from 'chart.js/auto';
export default{
    name:"ChartStat",
    props:["exception"],
    data(){
        return {
            chart:null
        }
    },
    mounted(){
        this.initChart();
    },
    methods:{
        initChart(){
            const ctx = document.getElementById("myChart");
            this.chart = new Chart(ctx,{
                type:'bar',
                data:{
                    datasets:[
                        {
                            data:[
                                {x:"Rétards",y:this.exception["r"]},
                                {x:"Départ anticipé",y:this.exception["d"]},
                                {x:"Absences",y:this.exception["a"]},
                            ],
                            backgroundColor:['#a0d0f57d','#ffb2c27d','#ffcfa37d'],
                            borderWidth:1,
                        },
                    ],
                },
                options:{
                    scales: {
                        y: {
                        beginAtZero: true
                        }
                    },
                    plugins:{
                        legend:{
                            display:false
                        }
                    }
                }
            });
        },
    }
}
</script>