import router from "../router/index.js";
import store from "../store/index.js";
import API from "./API.js";
import toast from "../components/ui/toast/index.js";

const Auth = {
    login(payload,cb){
        const {identifier,password} = payload;
        API.post("auth/login",{identifier,password}).then((response)=>{
            if(response.status === 200){
                toast.success("Connexion réussie !","Bienvenue sur notre Attendance RH 🤗, "+(response.data.user["first_name"]),6000);
                store.dispatch("login",{
                    token:response.data.access_token,
                    userId:response.data.user.id,
                    profil:response.data.user.profile.label,
                    firstName:response.data.user["first_name"],
                    lastName:response.data.user["last_name"],
                    supId:response.data.user["profile"]["sup_id"]
                });
                router.push({path:"/"});
            }else{
                toast.error("Erreur!!!!😖",response.data["message"],5000);
            }
        })
        .finally(()=>{
            cb();
        });
    },
    logout(){
        API.get("auth/logout").then((response)=>{
            if(response.data.status){
                toast.warning("Déconnexion","Retour à la page de connexion",2000);
                router.push({name:"signin"});
                store.dispatch("logout");
            }else{
                console.log("error")
            }
        });
    },
    updatePassword(payload,cb){
        const {old_password,new_password} = payload;
        const formData = new FormData();
        formData.append("old_password",old_password);
        formData.append("new_password",new_password);
        API.post("auth/updatePassword",formData).then((response)=>{
            if(response.data.status){
                toast.success("Mise à jour","Mot de passe mis à jour",2000);
                router.push({name:"signin"});
                store.dispatch("logout");
            }else{
                toast.error("Mise à jour","Une erreur s'est produite",2000);
            }
        }).finally(()=>{
            cb();
        })
    }
};

export default Auth;

// {
//     "today_presences": [
//         {
//             "id": 733,
//             "hour_start": "08:30:00",
//             "hour_end": "17:30:00",
//             "state": "2",
//             "comment": "",
//             "user_id": 11,
//             "created_at": "2024-12-05T00:00:00.000000Z",
//             "updated_at": "2024-11-27T15:44:08.000000Z",
//             "zone_id": null,
//             "user": {
//                 "id": 11,
//                 "first_name": "Dj\u00e9dj\u00e9",
//                 "last_name": "Junior",
//                 "phone": "0768502651",
//                 "salary": 60000,
//                 "shift": "d",
//                 "status": "permanent",
//                 "date_start": "2024-12-03",
//                 "date_end": "2024-12-03",
//                 "email": "junior.djedje@lce-ci.com",
//                 "serial": null,
//                 "mac_address": null,
//                 "profile_id": 4,
//                 "created_at": "2024-09-23T10:37:03.000000Z",
//                 "updated_at": "2024-10-04T20:52:11.000000Z",
//                 "deleted_at": null
//             }
//         }
//     ],
//     "presences": 364,
//     "absences": 337,
//     "employees": 10,
//     "departements": 6,
//     "exceptions": {
//         "a": [
//             78,
//             71,
//             57,
//             57,
//             63,
//             4,
//             0
//         ],
//         "r": [
//             1,
//             0,
//             4,
//             4,
//             1,
//             0,
//             0
//         ],
//         "d": [
//             2,
//             1,
//             1,
//             2,
//             1,
//             0,
//             0
//         ]
//     },
//     "labels": [
//         "Lundi",
//         "Mardi",
//         "Mercredi",
//         "Jeudi",
//         "Vendredi",
//         "Samedi",
//         "Dimanche"
//     ]
// }