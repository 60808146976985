<template>
    <div class="flex items-center justify-between p-2 cursor-pointer" @click="expand">
        <span class="link-title text-2xl">Gestion</span>
        <ChevronDown color="#707071" :size="18"/>
    </div>
    <div class="flex flex-col gap-y-2 px-2" v-if="isExpanded">
        <RouterLink to="/presences" class="link-btn">
            <CircleCheckBig class="icon" :size="18"/>
            <span class="text-2xl text-[#606675]">Présences</span>
        </RouterLink>

        <RouterLink to="/exceptions" class="link-btn">
            <MessageSquareWarning class="icon" :size="18"/>
            <span class="text-2xl text-[#606675]">Exceptions</span>
        </RouterLink>

        <RouterLink to="/requetes" class="link-btn">
            <GitPullRequestArrow class="icon" :size="18"/>
            <span class="text-2xl text-[#606675]">Requêtes</span>
        </RouterLink>
    </div>
</template>
<script>
import { ChevronDown,CircleCheckBig,MessageSquareWarning,GitPullRequestArrow } from 'lucide-vue-next';
import { RouterLink } from 'vue-router';
export default {
    name : "sideBarHead3",
    components:{
        ChevronDown,
        CircleCheckBig,
        MessageSquareWarning,
        GitPullRequestArrow,
        RouterLink
    },
    props:[],
    data(){
        return {
            isExpanded : true
        }
    },
    methods:{
        expand(){
            this.isExpanded = !this.isExpanded;
        }
    }
}
</script>