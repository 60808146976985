<template>
    <!-- <HeadComponent />
    <MainsideComponent target="2" /> -->

    <!--  -->
    <section class="">
        <div class="">

            <!--  -->

            <div class="block-header">
                <h2>REGISTRE</h2>
            </div>
            <AccessTableComponent />


        </div>
        <!--  -->
        <div class="page-loader-wrapper" v-if="loader">
            <div class="loader">
                <div class="preloader">
                    <div class="spinner-layer pl-indigo">
                        <div class="circle-clipper left">
                            <div class="circle"></div>
                        </div>
                        <div class="circle-clipper right">
                            <div class="circle"></div>
                        </div>
                    </div>
                </div>
                <p>Chargement...</p>
            </div>
        </div>
    </section>
</template>

<script>
// import HeadComponent from '@/components/menu/HeadComponent'
// import MainsideComponent from '@/components/menu/MainsideComponent'
import AccessTableComponent from '@/components/tables/AccessTable'
import Chart from 'chart.js/auto'
import API from "../../service/API.js";


export default {
    name: 'AccessView',
    components: {
        // HeadComponent,
        // MainsideComponent,
        AccessTableComponent
    },
    data() {
        return {
            loader: false,
            user_filter: 'e',
            barchart: null,
            data: {},
            users: [{ 'id': 0, 'first_name': 'Tout', 'last_name': '' }],
            posts: [{ 'id': 0, 'label': 'Tout' }],
            workhours: [],
            idFilter: 0
        }
    },
    computed: {
        comp1() {
            return ""
        }
    },
    async mounted() {
        this.loader = true
        await this.getUsersAndPosts()
        await this.getData()
        await this.getWorkHours()
        this.loader = false
    },
    unmounted() {

    },
    created() {

    },
    methods: {

        getData() {
            API.get('/moyennePointage')
                .then((res) => {
                    this.data = res.data
                })
                .catch(() => {
                    this.loader = false
                })
        },

        async getWorkHours() {
            API.post('/getWorkHours', { filter: this.user_filter, id: this.idFilter })
                .then((res) => {
                    this.workhours = res.data.data
                    this.initchart()
                })
                .catch(() => {
                    this.loader = false
                })
        },

        async getUsersAndPosts() {
            this.users = [{ 'id': 0, 'first_name': 'Tout', 'last_name': '' }]
            this.posts = [{ 'id': 0, 'label': 'Tout' }],
                API.get('/getUsersAndPosts')
                    .then((res) => {
                        res.data.users.forEach(user => {
                            this.users.push(user)
                        });
                        res.data.posts.forEach(post => {
                            this.posts.push(post)
                        });
                    })
                    .catch(() => {
                        this.loader = false
                    })
        },

        initchart() {
            if (this.barchart != null)
                this.barchart.destroy()
            this.barchart
                = new Chart(
                    document.getElementById('stats'),
                    {
                        type: 'line',
                        data: {
                            labels: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aou', 'Sep', 'Oct', 'Nov', 'Déc'],
                            datasets: [
                                {
                                    label: '',
                                    data: this.workhours,
                                },
                            ]
                        },
                        options: {
                            responsive: true,
                            legend: false
                        }
                    }
                )
        },

        barchatSearch(id){
            this.idFilter = id
            this.getWorkHours()
        },

        user_filtering(fil) {
            if (fil != this.filter) {
                this.idFilter = 0
                this.user_filter = fil
                this.getWorkHours()
            }
        },

    }
}

</script>