<template>
    <Teleport to="body">
        <div class="fixed top-2 right-2 z-[22222222] p-2 flex flex-col gap-y-2">
            <div class="bg-white min-w-[370px] rounded-xl shadow-md py-6 px-16 relative" v-for="(message,index) in toasts" :key="index">
                <span class="absolute top-3 left-3" v-if="message.type === 'success'">
                    <CircleCheck color="#fff" fill="#24bf5e" :size="28"/>
                </span>
                <span class="absolute top-3 left-3" v-else-if="message.type === 'error'">
                    <Info color="#fff" fill="#f54f4e" :size="28"/>
                </span>
                <span class="absolute top-3 left-3" v-else-if="message.type === 'warning'">
                    <TriangleAlert color="#fff" fill="#fe9d02" :size="28"/>
                </span>
                <span class="absolute top-3 left-3" v-else>
                    <Info color="#fff" fill="#3150ec" :size="28"/>
                </span>
                <h2 class="font-semibold text-2xl mb-1 text-[#495873]">{{message.title}}</h2>
                <p class="text-gray-500 font-extralight text-2xl">{{message.message}}</p>
                <button class="absolute top-3 right-3" @click="deleteToast(message)">
                    <X color="#495873" :size="20"/>
                </button>
            </div>
        </div>
    </Teleport>
</template>
<script>
import {X,CircleCheck,Info,TriangleAlert} from "lucide-vue-next";
export default {
    components: { X,CircleCheck,Info,TriangleAlert },
    methods: {
        deleteToast(msg){
            this.$store.dispatch("remove",msg);
        }    
    },
    computed: {
        toasts(){
            return this.$store.state.toast.messages;
        },
    }
}
</script>