<template>
    <DialogRoot>
        <DialogTrigger>
            <button :disabled="isSigned(valSup)" :class="getColor(isSup)" class="p-3 rounded-lg text-white min-w-[45%]">Refuser</button>
        </DialogTrigger>
        <DialogPortal>
            <DialogOverlay className="fixed z-[1000] inset-0 bg-[#00000044] data-[state=open]:animate-overlayShow"/>
            <DialogContent className="fixed w-[45%] z-[1001] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-xl bg-white py-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none data-[state=open]:animate-contentShow">
                <DialogTitle class="m-0 text-[20px] font-normal px-[25px] text-black flex items-start justify-between">
                    Motif de rejet
                    <DialogClose>
                        <X :size="20" stroke-width="1.2" class="cursor-pointer"/>
                    </DialogClose>
                </DialogTitle>
                <DialogDescription class="text-black px-[25px] mt-4 text-[14px] font-extralight">
                    Le champ ci-dessous n'est pas obligatoire, le message sera envoyé au collaborateur
                </DialogDescription>
                <form @submit.prevent="submitForm" class="px-[25px] text-black mt-4">
                    <div class="w-full flex flex-col items-start mt-2">
                        <label for="detail" class="font-extralight">Détails<span class="text-[#f44545]">*</span></label>
                        <textarea v-model="motif" name="details" id="" class="rounded-md bg-[#f9fafb] border p-5 h-[35vh] w-full"></textarea>
                    </div>
                    <div className="w-full mt-4 gap-x-3 flex items-center justify-end">
                            <DialogClose asChild><button :disabled="asSend" class="px-8 py-3 text-[#797e91] transition-colors hover:bg-[#e9e9ec] rounded-md">Cancel</button></DialogClose>
                                <button @click="$emit('validRequest',{id:id,cb:switchAsSend,action:'reject',motif:motif})" :disabled="asSend" class="px-8 py-3 bg-[#5357ec] text-white rounded-md">
                                    <Loader2 v-if="asSend" class="animate-spin mx-auto"/>
                                    <span v-if="!asSend">Enregistrer</span>
                                </button>
                    </div>
                </form>
            </DialogContent>
        </DialogPortal>
    </DialogRoot>
    
</template>
<script>
import {
    X,
    Loader2
} from "lucide-vue-next"
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
  DialogTitle,
  DialogTrigger,
} from 'radix-vue';
export default {
    name:"RejectComponent",
    components:{
        Loader2,
        DialogClose,
        DialogContent,
        DialogDescription,
        DialogOverlay,
        DialogPortal,
        DialogRoot,
        DialogTitle,
        DialogTrigger,
        X,
    },
    props:["valSup","id"],
    data(){
        return {
            asSend:false,
            motif:""
        }
    },
    methods:{
        switchAsSend(){
            this.asSend = !this.asSend;
        },
        isSigned(isSup){
          if(typeof isSup == "undefined") return false;
          return isSup == 1
        },
        getColor(isSup){
            return isSup == 1 ? "bg-[#6d6d6d]" : "bg-[#f44545]"
        }
    }
}
</script>