<template>
    <div class="w-full h-full text-black">
        <h2 class="text-[20px] font-normal">Statistique</h2>
        <p class="font-extralight my-4">Visualiser les statistiques relatif à votre compte</p>
        <ChartStat :exception="exception"/>
    </div>
</template>
<script>
import ChartStat from "./chart-stat.vue";
export default{
    name:"ProfilStat",
    props:["exception"],
    components:{
        ChartStat
    },
    data(){
        return {
            chart:null
        }
    },
    methods:{
    }
}
</script>