import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import SigninView from '@/views/auth/SigninView'
import ErrorView from '@/views/404View'

import DashboardView from '@/views/navigation/DashboardView'
import PresenceView from '@/views/navigation/PresenceView'
import AccessView from '@/views/navigation/AccessView'
import ExceptionView from '@/views/navigation/ExceptionView'
import RequestView from '@/views/navigation/RequestView'
import EmployeeView from '@/views/navigation/EmployeeView'
import ZonesView from '@/views/navigation/ZonesView'
import DaysView from '@/views/navigation/DaysView'
import DepartementView from '@/views/navigation/DepartementView'
import ShiftsView from '@/views/navigation/ShiftsView'
import HomePage from "@/views/navigation/home-page.vue"
import MyException from "@/views/navigation/my-exception.vue"
import MyPermission from "@/views/navigation/my-permission.vue"
import MyTeam from "@/views/navigation/my-team.vue";
import DetailsMyTeam from "@/views/navigation/details-my-team.vue";
import ProfileVue from "@/views/navigation/profil-vue.vue";
import MyDemande from "@/views/navigation/my-demande.vue";

const routes = [
  {
    path: '/signin',
    name: 'signin',
    meta : {requiresUnAuth : true},
    component: SigninView
  },
  {
    path:"/",
    component: HomePage,
    meta : {requiresAuth : true},
    children: [
      {
        path: '/',
        component: DashboardView
      },
      {
        path: '/my-exceptions',
        name: 'my-exceptions',
        component: MyException
      },
      {
        path: '/my-permissions',
        name: 'my-permissions',
        component: MyPermission
      },
      {
        path: '/my-demande',
        name: 'my-demande',
        component: MyDemande
      },
      {
        path:"/my-profile",
        name:"my-profile",
        component: ProfileVue
      },
      {
        path: '/my-teams',
        name: 'my-teams',
        component: MyTeam
      },
      {
        path:'my-teams/:name/:teamId',
        name:'details-my-team',
        component:DetailsMyTeam
      },
      {
        path: '/presences',
        name: 'presences',
        component: PresenceView
      },
      {
        path: '/access',
        name: 'access',
        component: AccessView
      },
      {
        path: '/exceptions',
        name: 'exceptions',
        component: ExceptionView
      },
      {
        path: '/requetes',
        name: 'requetes',
        component: RequestView
      },
      {
        path: '/employes',
        name: 'employes',
        component: EmployeeView
      },
      {
        path: '/departements',
        name: 'departements',
        component: DepartementView
      },
      {
        path: '/shifts',
        name: 'shifts',
        component: ShiftsView
      },
      {
        path: '/zonesautorisees',
        name: 'zones',
        component: ZonesView
      },
      {
        path: '/joursautorises',
        name: 'days',
        component: DaysView
      },
    ]
  },
  {
    path: '/:catchAll(.*)',
    component: ErrorView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkExactActiveClass : "active-nav-link",
})

router.beforeEach(function(to,_,next){
 if(to.meta.requiresAuth && !store.getters.isAuthenticated){
   next("/signin");
 }else if(to.meta.requiresUnAuth && store.getters.isAuthenticated){
   next("/");
 }else{
   next();
 }
});

export default router
