import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";
export default {
    state(){
        return {
            userId : null,
            token : null,
            profil : null,
            firstName : null,
            lastName : null,
            supId : null
        }
    },
    mutations,
    actions,
    getters
}