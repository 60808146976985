<template>
  <ToastUi/>
  <router-view/>
</template>
<script>
import ToastUi from "@/components/ui/toast/toast-ui.vue";
  export default {
    components: {
      ToastUi,
    },
    created(){
      this.$store.dispatch("tryLogin");
    },
  }
</script>