<template>
    <div class="mb-4">
            <h2 class="text-4xl text-[#2b2b2b]">Mon profil</h2>
    </div>
    <div class="bg-white rounded-lg p-4 mb-3">
        <div class="flex items-center justify-between">
            <div class="flex items-center gap-x-1 text-black font-light rounded-md">
                <span v-bind:class="getActiveClass('Sécurité')" v-on:click="selectBar('Sécurité')">Sécurité</span>
                <span v-bind:class="getActiveClass('Statistique')" v-on:click="selectBar('Statistique')">Statistique</span>
                <span v-bind:class="getActiveClass('Rapport')" v-on:click="selectBar('Rapport')">Rapport</span>
            </div>
            <div class="flex items-center gap-x-2">
                    <input type="date" class="p-3 border rounded-md text-black cursor-pointer" v-model="formValue.debut">
                    <input type="date" class="p-3 border rounded-md text-black cursor-pointer" v-model="formValue.fin">
                    <button :disabled="asSend" class="px-5 py-3 rounded-md border text-black" v-on:click="fetchAll('actualise')">
                        <Loader2 v-if="asSend" class="animate-spin mx-auto"/>
                        <span v-if="!asSend">Actualiser</span>
                    </button>
                </div>
        </div>
    </div>
    <div class="p-4 rounded-lg bg-white">
        <div v-if="isActiveVue('Sécurité')" class="w-full h-full">
            <SecurityVue />
        </div>
        <div v-if="isActiveVue('Statistique')" class="w-full h-full">
            <ProfilStat :exception="exception" v-if="!isload"/>
        </div>
        <div v-if="isActiveVue('Rapport')" class="w-full h-full">
            <ProfilRapport :presence="presence" :isload="isload"/>
        </div>
    </div>
</template>
<script>
import SecurityVue from "@/components/ui/security/security-vue.vue";
import ProfilStat from "./profil-stat.vue";
import {Loader2} from "lucide-vue-next";
import API from "../../service/API.js";
import axios from "axios";
import ProfilRapport from "./profil-rapport.vue";
export default {
    name : "ProfileVue",
    components:{
        Loader2,
        SecurityVue,
        ProfilStat,
        ProfilRapport
    },
    data(){
        return{
            active:"Sécurité",
            isload : true,
            asSend:false,
            presence:[],
            exception:{
                r:0,
                d:0,
                a:0
            },
            formValue:{
                debut:null,
                fin:null
            },
        }
    },
    mounted(){
        this.fetchAll();
    },
    methods:{
        closeProfil(){
            this.$store.dispatch("switchShowProfil",false);
        },
        getActiveClass(libelle){
            return `px-4 py-3 rounded-md cursor-pointer ${libelle == this.active ? "bg-[#5357eb] text-white" : "bg-[#f9fafb] text-black"}`;
        },
        selectBar(libelle){
            this.active = libelle;
        },
        isActiveVue(libelle){
            return this.active === libelle;
        },
        getCurrentDate(){
            const date = new Date();
            const years = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2,0);
            const day = date.getDate().toString().padStart(2,0);
            return `${years}-${month}-${day}`;
        },
        fetchAll(action=""){
            if(action) this.asSend = true;
            const currentDate = this.getCurrentDate();
            const formData = new FormData();
            formData.append("start",action ? ((this.formValue.debut ?? this.formValue.fin) ?? currentDate) :currentDate);
            formData.append("end",action ? ((this.formValue.fin ?? this.formValue.debut) ?? currentDate) :currentDate);
            let endpoints = [
                "getUserStatsPresences/"+(localStorage.getItem("userId") ?? this.$store.getters.userId),
                "getUserStatsExceptions/"+(localStorage.getItem("userId") ?? this.$store.getters.userId)
            ];
            axios.all(endpoints.map((endpoint)=>API.post(endpoint,formData)))
            .then((response)=>{
                console.log("Présence : ",response[0].data["data"]);
                this.presence = response[0].data["data"];
                this.exception = response[1].data["data"];
            })
            .catch()
            .finally(()=>{
                if(action){
                    this.asSend = false;
                }
                this.isload = false;
            })
        }
    },
}
</script>