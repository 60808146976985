<template>
    <div class="mb-4">
            <h2 class="text-4xl text-[#2b2b2b]">Permissions équipe</h2>
    </div>
    <div class="bg-white rounded-lg p-4 mb-3">
        <div class="flex items-center justify-between">
            <div>
                <p class="mt-2 text-[#929292] font-extralight">
                    Liste des permissions demandé par votre équipe, appuyer sur Valider<span class="text-[#f44545]">*</span> ou Refuser<span class="text-[#f44545]">*</span> pour répondre
                </p> 
            </div>
            <div class="flex items-center gap-x-2">
                <input type="date" class="p-3 border rounded-md text-black cursor-pointer" v-model="date">
                <button :disabled="asSend" class="px-5 py-3 rounded-md border text-black" v-on:click="getMembersRequest('click')">
                    <Loader2 v-if="asSend" class="animate-spin mx-auto"/>
                    <span v-if="!asSend">Actualiser</span>
                </button>
            </div>
        </div>
    </div>
    <div class="p-4 rounded-lg bg-white">
        <table v-if="!isload" class="w-full">
            <thead class="w-full">
                    <tr class="text-black flex justify-between w-full py-3">
                        <th class="font-extralight w-[8%] pl-[13px]">ID Perm</th>
                        <th class="font-extralight w-[9%] pl-[13px]">Début</th>
                        <th class="font-extralight w-[9%] pl-[13px]">Fin</th>
                        <th class="font-extralight w-[20%] pl-[12px]">Collaborateur</th>
                        <th class="font-extralight w-[12%] pl-[13px]">Type</th>
                        <th class="font-extralight w-[16%] pl-[13px]">Motif</th>
                        <th class="font-extralight w-[10%] pl-[13px]">Pièce jointe</th>
                        <th class="font-extralight w-[16%] pl-[13px]">Action</th>
                    </tr>
            </thead>
            <tbody v-if="permission.length != 0" class="w-full flex flex-col gap-y-2">
                    <tr class="text-black bg-[#f9fafb] font-extralight rounded-lg flex items-center justify-between w-full py-3" v-for="perm in permission" :key="perm.id">
                        <td class="font-extralight w-[8%] pl-[13px]">{{ perm["id"] }}</td>
                        <td class="font-extralight w-[9%] pl-[13px]">{{ perm["exception"]["date_start"] }}</td>
                        <td class="font-extralight w-[9%] pl-[13px]">{{ perm["exception"]["date_end"] }}</td>
                        <td class="font-extralight w-[20%] pl-[12px]">{{ perm["exception"]["user"]["first_name"] +" "+perm["exception"]["user"]["last_name"] }}</td>
                        <td class="font-extralight w-[12%] pl-[13px]">{{ perm["exception"]["type"]["label"] }}</td>
                        <td class="font-extralight w-[16%] pl-[13px]">{{ perm["motif_id"] }}</td>
                        <td class="font-extralight w-[10%] pl-[13px]">{{ isPieceJointe(perm["file"]) }}</td>
                        <td class="font-extralight w-[16%] pl-[13px] flex items-center gap-x-2">
                            <ValidComponent :valSup="perm['validation_sup']" :id='perm["id"]' @valid-request="validateOrRejectRequest"/>
                            <RejectComponent :valSup="perm['validation_sup']" :id='perm["id"]' @valid-request="validateOrRejectRequest"/>
                        </td>
                    </tr>
                </tbody>
                <tr v-else class= "text-black bg-[#f9fafb] font-extralight rounded-lg flex justify-center w-full py-20">
                        Aucune donnée
                </tr>
        </table>
    </div>
</template>
<script>
import API from "../../service/API.js"
import {Loader2} from "lucide-vue-next";
import ValidComponent from "@/components/perm/valid-compent.vue";
import RejectComponent from "@/components/perm/reject-component.vue";
import toast from "@/components/ui/toast/index.js";
export default {
    name:'MyPermission',
    components:{
        Loader2,
        ValidComponent,
        RejectComponent
    },
    data(){
        return {
            isload : true,
            asSend:false,
            date:"",
            permission:[]
        }
    },
    mounted(){
        this.getMembersRequest();
    },
    computed:{
    },
    methods:{
        isPieceJointe(pj){
            return pj == null ? "Aucun justif" : pj.length + " docs";
        },
        isSigned(isSup){
          return isSup == 1
        },
        getMembersRequest(click=""){
            if(click){this.asSend = true;}
            const date = new Date();
            const years = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2,0);
            const day = date.getDate().toString().padStart(2,0);
            const id = localStorage.getItem("userId") ?? this.$store.getters.userId;
            const formData = new FormData();
            formData.append("sup",true);
            formData.append("date",this.date ? `${this.date.split("-")[0]}-${this.date.split("-")[1]}-01` : `${years}-${month}-${day}`)
            API.post("/getMembersRequests/"+id,formData)
            .then((data)=>{
                this.permission = data.data["data"];
            })
            .catch((error)=>{
                console.log(error)
            })
            .finally(()=>{
                if(click){this.asSend = false;}
                else{this.isload = false;}
            })
        },
        validateOrRejectRequest(payload){
            const {id,cb,action,motif} = payload;
            cb();
            const formData = new FormData();
            formData.append("sup",true);
            formData.append("reject_motif",motif);
            API.post((action == "valid" ? "validate" : "reject")+"Request/"+id,formData)
            .then((response)=>{
                if(response.data["status"]){
                    this.getMembersRequest();
                    toast.success("Succès",`Permission ${action == "valid" ? "validé" : "rejeté"} avec succès`,3000)
                }
            })
            .catch()
            .finally(()=>{
                cb();
            })
        }
    }
}
</script>