<template>
    <DialogRoot>
        <DialogTrigger>
            <button className="flex items-center gap-x-3 py-3 px-8 rounded-md text-white bg-[#5357ec]">
                <UserPlus :size="18" color="#FFF"/>
                <span>Ajouter un employé</span>
            </button>
        </DialogTrigger>
        <DialogPortal>
            <DialogOverlay className="fixed z-[1000] inset-0 bg-[#00000044] data-[state=open]:animate-overlayShow"/>
            <DialogContent className="fixed w-[48%] z-[1001] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-xl bg-white pt-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none data-[state=open]:animate-contentShow">
                <DialogTitle class="m-0 text-[20px] font-medium text-center px-[25px] text-black">
                    Ajouter un nouveau collaborateur
                </DialogTitle>
                <DialogDescription class="text-black px-8 mt-4 text-[15px] text-center font-extralight">
                    Les champs du formulaire suivis d'un <span class="text-[#f44545]">*</span> sont obligatoire. veuillez lire le formulaire
                    avant soummition
                </DialogDescription>
                <form @submit.prevent="submitForm">
                    <div class="w-full flex items-center justify-between px-6 pt-4 text-black">
                        <div class="w-[49%] flex flex-col items-start ">
                            <label for="name" class="font-light" >Nom<span class="text-[#f44545]">*</span></label>
                            <input type="text" v-model="userdata.first_name" name="name" class="h-[40px] w-full rounded-md border outline-none p-3 focus:outline-none" placeholder="John">
                        </div>

                        <div class="w-[49%] flex flex-col items-start ">
                            <label for="lastname" class="font-light" >Prenom<span class="text-[#f44545]">*</span></label>
                            <input type="text" placeholder="Doe" v-model="userdata.last_name" name="lastname" class="h-[40px] w-full rounded-md border outline-none p-3 focus:outline-none">
                        </div>
                    </div>

                    <div class="w-full flex flex-col px-6 pt-4 text-black">
                        <label for="email" class="font-light" >Email<span class="text-[#f44545]">*</span></label>
                        <input type="email" placeholder="john@gmail.com" v-model="userdata.email" name="email" class="h-[40px] w-full rounded-md border outline-none p-3 focus:outline-none">
                    </div>

                    <div class="w-full flex items-center justify-between px-6 pt-4 text-black">
                        <div class="w-[49%] flex flex-col items-start ">
                            <label for="tel" class="font-light" >Téléphone<span class="text-[#f44545]">*</span></label>
                            <input type="tel" placeholder="0707070707" v-model="userdata.phone" name="tel" class="h-[40px] w-full rounded-md border outline-none p-3 focus:outline-none">
                        </div>

                        <div class="w-[49%] flex flex-col items-start ">
                            <label for="contrat" class="font-light" >Type de contrat<span class="text-[#f44545]">*</span></label>
                            <select v-model="userdata.status" name="contrat" id="contrat" class="h-[40px] w-full rounded-md border outline-none p-3 focus:outline-none">
                                <option value="">Choisissez le type</option>
                                <option value="consultant">Consultant</option>
                                <option value="temporaire">Temporaire</option>
                                <option value="permanent">Permanent</option>
                            </select>
                        </div>
                    </div>

                    <div class="w-full flex items-center justify-between px-6 pt-4 text-black">
                        <div class="w-[49%] flex flex-col items-start ">
                            <label for="departement" class="font-light" >Département<span class="text-[#f44545]">*</span></label>
                            <select v-model="userdata.profile_id" name="departement" id="contrat" class="h-[40px] w-full rounded-md border outline-none p-3 focus:outline-none">
                                <option value="">Choisissez le département</option>
                                <option v-for="profile in profiles" :key="profile.id" :value="profile.id"> {{
                                            profile.label }} </option>
                            </select>
                        </div>

                        <div class="w-[49%] flex flex-col items-start ">
                            <label for="horaire" class="font-light" >Horaire<span class="text-[#f44545]">*</span></label>
                            <select v-model="userdata.shift" name="horaire" id="contrat" class="h-[40px] w-full rounded-md border outline-none p-3 focus:outline-none">
                                <option value="">Choisissez la plage horaire</option>
                                <option v-for="shift in shifts" :key="shift.id" :value="shift.symbol"> {{
                                            shift.label }} </option>
                            </select>
                        </div>
                    </div>

                    <div class="w-full flex items-center justify-between px-6 pt-4 text-black">
                        <div class="w-[49%] flex flex-col items-start ">
                            <label for="debut" class="font-light" >Début contrat<span class="text-[#f44545]">*</span></label>
                            <input v-model="userdata.date_start" type="date" name="debut" class="h-[40px] w-full rounded-md border outline-none p-3 focus:outline-none">
                        </div>

                        <div class="w-[49%] flex flex-col items-start ">
                            <label for="fin" class="font-light" >Fin contrat<span class="text-[#f44545]">*</span></label>
                            <input v-model="userdata.date_end" type="date" name="fin" class="h-[40px] w-full rounded-md border outline-none p-3 focus:outline-none">
                        </div>
                    </div>

                    <div class="w-full flex items-center justify-between px-6 pt-4 text-black">
                        <div class="w-[49%] flex flex-col items-start ">
                            <label for="serial" class="font-light" >Identifiant<span class="text-[#f44545]">*</span></label>
                            <input v-model="userdata.serial" type="text" name="serial" class="h-[40px] w-full rounded-md border outline-none p-3 focus:outline-none">
                        </div>

                        <div class="w-[49%] flex flex-col items-start ">
                            <label for="matricule" class="font-light" >Matricule<span class="text-[#f44545]">*</span></label>
                            <input v-model="userdata.matricule" type="text" name="matricule" class="h-[40px] w-full rounded-md border outline-none p-3 focus:outline-none">
                        </div>
                    </div>


                    <div className="bg-[#f4f4f7] w-full py-4 mt-4 px-[25px] rounded-b-md flex items-center justify-between">
                            <DialogClose asChild><button :disabled="asSend" class="px-8 py-3 text-[#797e91] transition-colors hover:bg-[#e9e9ec] rounded-md">Cancel</button></DialogClose>
                                <button type="submit" :disabled="asSend" class="px-8 py-3 bg-[#5357ec] text-white rounded-md">
                                    <Loader2 v-if="asSend" class="animate-spin mx-auto"/>
                                    <span v-if="!asSend">Enregistrer</span>
                                </button>
                    </div>
                </form>
            </DialogContent>
        </DialogPortal>
    </DialogRoot>
</template>
<script>
import {UserPlus,Loader2} from "lucide-vue-next";
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
  DialogTitle,
  DialogTrigger,
} from 'radix-vue'
import API from "@/service/API.js";
import toast from "../ui/toast";
export default {
    name: "AddUser",
    components:{
        UserPlus,
        Loader2,
        DialogClose,
        DialogContent,
        DialogDescription,
        DialogOverlay,
        DialogPortal,
        DialogRoot,
        DialogTitle,
        DialogTrigger
    },
    data(){
      return {
        asSend:false,
        profiles: [],
        shifts: [],
        userdata: {
                first_name: "",
                last_name: "",
                email: "",
                phone: null,
                profile_id: "",
                serial: null,
                shift: "",
                matricule: "",
                date_start:null,
                date_end:null,
                status:null
        }
      }
    },
    mounted() {
        this.getProfiles()
        this.getShifts()
    },
    methods:{
        resetForm(){
            this.userdata = {
                first_name: "",
                last_name: "",
                email: "",
                phone: null,
                profile_id: "",
                serial: null,
                matricule: "",
                shift: "",
                date_start:null,
                date_end:null,
                status:null
        }
        },
        switchSender(){
            this.asSend = !this.asSend;
        },
        submitForm(){
            this.switchSender();
            API.post('auth/signup',this.userdata)
            .then((res)=>{
                console.log(res);
                if(res.status != 422){
                  this.resetForm();
                  this.closeModal();
                  toast.success("Succès","Collaborateur enrégistré",3000);
                }else{
                  toast.error("Oops..",res.data["message"],4000)
                }
            })
            .catch((err)=>{
                console.log(err);
                toast.error("Oops..","Il semble avoir une erreur, merci de réessayé.",4000)
            }).finally(()=>{this.switchSender();})
        },
        getProfiles() {
            API.get('getAllProfiles')
                .then((res) => {
                    this.profiles = res.data.data
                })
                .catch((error) => {
                    console.error(error)
                })
        },
        getShifts() {
            API.get('shifts')
                .then((res) => {
                    this.shifts = res.data.data
                })
                .catch((error) => {
                    console.error(error)
                })
        },
    }
}
</script>