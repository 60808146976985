<template>
    <div class="w-full">
        <div class="mb-4 flex items-center justify-between">
            <h2 class="text-4xl text-[#2b2b2b]">Détails équipe</h2>
        </div>
        <div class="bg-white rounded-lg p-4 mb-3">
            <div class="flex items-center justify-between">
                <div class="flex items-center gap-x-1 text-black font-light rounded-md">
                    <span v-bind:class="getActiveClass('Statistique')" v-on:click="selectBar('Statistique')">Statistique</span>
                    <span v-bind:class="getActiveClass('Exception')" v-on:click="selectBar('Exception')">Exception</span>
                    <span v-bind:class="getActiveClass('Permission')" v-on:click="selectBar('Permission')">Permission</span>
                </div>
                <div class="flex items-center gap-x-2">
                    <input type="date" class="p-3 border rounded-md text-black cursor-pointer" v-model="formValue.debut">
                    <input type="date" class="p-3 border rounded-md text-black cursor-pointer" v-model="formValue.fin">
                    <button :disabled="asSend" class="px-5 py-3 rounded-md border text-black" v-on:click="fetchAll('actualise')">
                        <Loader2 v-if="asSend" class="animate-spin mx-auto"/>
                        <span v-if="!asSend">Actualiser</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="p-4 rounded-lg bg-white">
            <h2 class="text-black text-[16px] font-light">{{ name }}</h2>
            <p class="mt-2 text-[#929292] font-extralight">
                Détails des informations sur le collaborateur 
            </p>
            <div v-if="getActiveTab('Statistique')" class="mt-6 flex justify-center">
                <ChartStat :exception="exception"/>
            </div>
            <div v-if="getActiveTab('Exception')" class="mt-6">
                <table v-if="!isload" class="w-full">
                    <thead class="w-full">
                        <tr class="text-black flex justify-between w-full py-3">
                            <th class="font-extralight w-[14%] pl-[13px]">ID Exception</th>
                            <th class="font-extralight w-[33%] pl-[13px]">Date</th>
                            <th class="font-extralight w-[33%] pl-[13px]">Type d'exception</th>
                            <th class="font-extralight w-[20%] pl-[13px]">Status</th>
                        </tr>
                    </thead>
                    <tbody v-if="exception_list.length != 0" class="w-full flex flex-col gap-y-2">
                        <tr class="text-black bg-[#f9fafb] font-extralight rounded-lg flex items-center justify-between w-full py-3" v-for="abs in exception_list" :key="abs.id">
                            <td class="w-[14%] pl-[13px]">{{ abs["id"] }}</td>
                            <td class="w-[33%] pl-[13px]">{{ abs["created_at"] }}</td>
                            <td class="w-[33%] pl-[13px]">{{ abs["type"]["label"] }}</td>
                            <td class="w-[20%] pl-[13px]">
                                <span class="py-2 px-3 rounded-2xl inline-flex items-center gap-x-2" :class="getStatusBgColors(abs['state'],abs['is_perm'])">
                                    <div class="h-[6px] w-[6px] rounded-full" :class="getDotColors(abs['state'],abs['is_perm'])"></div>
                                    <span :class="getStatusColors(abs['state'],abs['is_perm'])">{{ getStatus(abs["state"],abs["is_perm"]) }}</span>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                    <tr v-else class= "text-black bg-[#f9fafb] font-extralight rounded-lg flex justify-center w-full py-20">
                            Aucune donnée....
                    </tr>
                </table>
            </div>
            <div v-if="getActiveTab('Permission')" class="mt-6">
                <table v-if="!isload" class="w-full">
                    <thead class="w-full">
                            <tr class="text-black flex justify-between w-full py-3">
                                <th class="font-extralight w-[9%] pl-[13px]">ID Perm</th>
                                <th class="font-extralight w-[11%] pl-[13px]">Début</th>
                                <th class="font-extralight w-[11%] pl-[13px]">Fin</th>
                                <th class="font-extralight w-[13%] pl-[13px]">Type</th>
                                <th class="font-extralight w-[20%] pl-[13px]">Motif</th>
                                <th class="font-extralight w-[16%] pl-[13px]">Pièce jointe</th>
                                <th class="font-extralight w-[20%] pl-[13px]">Action</th>
                            </tr>
                    </thead>
                    <tbody v-if="permission.length != 0" class="w-full flex flex-col gap-y-2">
                            <tr class="text-black bg-[#f9fafb] font-extralight rounded-lg flex items-center justify-between w-full py-3" v-for="perm in permission" :key="perm.id">
                                <td class="font-extralight w-[9%] pl-[13px]">{{ perm["id"] }}</td>
                                <td class="font-extralight w-[11%] pl-[13px]">{{ perm["date_start"] }}</td>
                                <td class="font-extralight w-[11%] pl-[13px]">{{ perm["date_end"] }}</td>
                                <td class="font-extralight w-[13%] pl-[13px]">{{ perm["type"]["label"] }}</td>
                                <td class="font-extralight w-[20%] pl-[13px]">{{ perm["requests"][0] ? (perm["requests"][0]["motif_id"] ? perm["requests"][0]["motif_id"] : "Iconnue") : "Iconnue" }}</td>
                                <td class="font-extralight w-[16%] pl-[13px]">{{ isPieceJointe(perm["requests"][0]) }}</td>
                                <td class="font-extralight w-[20%] pl-[13px] flex items-center gap-x-2">
                                    <ValidComponent :valSup="(perm['requests'][0]?.validation_sup ?? 0)" :id='perm["id"]' @valid-request="validateOrRejectRequest"/>
                                    <RejectComponent :valSup="(perm['requests'][0]?.validation_sup ?? 0)" :id='perm["id"]' @valid-request="validateOrRejectRequest"/>
                                </td>
                            </tr>
                        </tbody>
                        <tr v-else class= "text-black bg-[#f9fafb] font-extralight rounded-lg flex justify-center w-full py-20">
                                Aucune donnée
                        </tr>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import {Loader2} from "lucide-vue-next";
import API from "../../service/API.js";
import ValidComponent from "@/components/perm/valid-compent.vue";
import RejectComponent from "@/components/perm/reject-component.vue";
import toast from "@/components/ui/toast/index.js";
import ChartStat from "./chart-stat.vue";

export default {
    name:"DetailsMyTeam",
    components:{
        Loader2,
        ValidComponent,
        RejectComponent,
        ChartStat
    },
    data(){
        return {
            active:"Statistique",
            name:"",
            id:"",
            isload : true,
            asSend:false,
            exception:{
                r:0,
                d:0,
                a:0
            },
            exception_list:[],
            assidute:0,
            permission:[],
            presence:[],
            formValue:{
                debut:null,
                fin:null
            },
            chart:null
        }
    },
    created(){
        this.name = this.$route.params.name;
        this.id = this.$route.params.teamId;
    },
    mounted(){
        this.fetchAll();
    },
    methods:{
        isPieceJointe(pj){
            if(pj){
              const obj = pj[0]
              return obj?.file ? obj.file.length + "docs" : "Aucun justif";
            }else{
                return "Aucun justif";
            }
        },
        getActiveClass(libelle){
            return `px-4 py-3 rounded-md cursor-pointer ${libelle == this.active ? "bg-[#5357eb] text-white" : "bg-[#f9fafb] text-black"}`;
        },
        getActiveTab(libelle){
            return this.active == libelle
        },
        selectBar(libelle){
            this.active = libelle;
        },
        getCurrentDate(){
            const date = new Date();
            const years = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2,0);
            const day = date.getDate().toString().padStart(2,0);
            return `${years}-${month}-${day}`;
        },
        fetchAll(action=""){
            if(action) this.asSend = true;
            const currentDate = this.getCurrentDate();
            const formData = new FormData();
            formData.append("start",action ? ((this.formValue.debut ?? this.formValue.fin) ?? currentDate) :currentDate);
            formData.append("end",action ? ((this.formValue.fin ?? this.formValue.debut) ?? currentDate) :currentDate);
            let endpoints = [
                "getUserStatsPresences/"+this.id,
                "getUserStatsPerformance/"+this.id,
                "getUserStatsPermissions/"+this.id,
                "getUserStatsExceptions/"+this.id
            ];
            axios.all(endpoints.map((endpoint)=>API.post(endpoint,formData)))
            .then((response)=>{
                console.log("permission : ",response[2].data["data"])
                this.presence = response[0].data["data"];
                this.assidute = response[1].data["data"];
                this.permission = response[2].data["data"].filter((perm)=>(perm["is_perm"] == 1 && perm["requests"].length != 0));
                this.exception = response[3].data["data"];
                this.exception_list = response[3].data["liste"];
            })
            .catch()
            .finally(()=>{
                if(action){
                    this.asSend = false;
                }
                this.isload = false;
            })
        },
        getStatus(state,isperm){
            if(state == "0"){
                return isperm == 1 ? "Non approuvé" : "Non justifié"
            }else if(state == "1"){
                return "Attente"
            }else{
                return isperm == 1 ? "Approuvé": "Justifié"
            }
        },
        getStatusColors(state,isperm){
            if(state == "0"){
                return isperm == 1 ? "text-[#f25050]" : "text-[#f25050]"
            }else if(state == "1"){
                return "text-[#f2c450]"
            }else{
                return isperm == 1 ? "text-[#50f27b]": "text-[#f2c450]"
            }
        },
        getDotColors(state,isperm){
            if(state == "0"){
                return isperm == 1 ? "bg-[#f25050]" : "bg-[#f25050]"
            }else if(state == "1"){
                return "bg-[#f2c450]"
            }else{
                return isperm == 1 ? "bg-[#50f27b]": "bg-[#f2c450]"
            }
        },
        getStatusBgColors(state,isperm){
            if(state == "0"){
                return isperm == 1 ? "bg-[#f7d8d8]" : "bg-[#f7d8d8]"
            }else if(state == "1"){
                return "bg-[#f7f6d8]"
            }else{
                return isperm == 1 ? "bg-[#d8f7de]": "bg-[#f7f6d8]"
            }
        },
        validateOrRejectRequest(payload){
            const {id,cb,action,motif} = payload;
            cb();
            const formData = new FormData();
            formData.append("sup",true);
            formData.append("reject_motif",motif);
            API.post((action == "valid" ? "validate" : "reject")+"Request/"+id,formData)
            .then((response)=>{
                if(response.data["status"]){
                    toast.success("Succès",`Permission ${action == "valid" ? "validé" : "rejeté"} avec succès`,3000)
                }else{
                    toast.error("Echèc",response.data["message"],5000)
                }
            })
            .catch()
            .finally(()=>{
                cb();
            })
        }
    }
}
</script>