<template>
    <div class="flex items-center justify-between p-2 cursor-pointer" @click="expand">
        <span class="link-title text-2xl">Mon compte</span>
            <ChevronDown color="#707071" :size="18"/>
    </div>
    <div class="flex flex-col gap-y-2 px-2" v-if="isExpanded">
        <RouterLink to="/my-exceptions" class="link-btn">
            <MessageCircleWarning class="icon" :size="18"/>
            <span class="text-2xl text-[#606675]">Exceptions</span>
        </RouterLink>

        <RouterLink v-if="isSup" to="/my-permissions" class="link-btn">
            <ClipboardCheck class="icon" :size="18"/>
            <span class="text-2xl text-[#606675]">Permissions</span>
        </RouterLink>

        <RouterLink v-if="isSup" to="/my-demande" class="link-btn">
            <DiamondPlus class="icon" :size="18"/>
            <span class="text-2xl text-[#606675]">Demande</span>
        </RouterLink>

        <RouterLink v-if="isSup" to="/my-teams" class="link-btn">
            <LucideNetwork class="icon" :size="18"/>
            <span class="text-2xl text-[#606675]">Mon équipe</span>
        </RouterLink>
        <RouterLink to="/my-profile" class="link-btn">
            <UserCircle class="icon" :size="18"/>
            <span class="text-2xl text-[#606675]">Mon profile</span>
        </RouterLink>
    </div>
</template>
<script>
import { ChevronDown,MessageCircleWarning,DiamondPlus,ClipboardCheck, LucideNetwork, UserCircle} from 'lucide-vue-next';
import { RouterLink } from 'vue-router';
export default {
    name : "sideBarHead2",
    components:{
        ChevronDown,
        MessageCircleWarning,
        ClipboardCheck,
        RouterLink,
        LucideNetwork,
        UserCircle,
        DiamondPlus
    },
    props:[],
    data(){
        return {
            isExpanded : true
        }
    },
    computed:{
        isSup(){
            return localStorage.getItem("supId");
        }
    },
    methods:{
        expand(){
            this.isExpanded = !this.isExpanded;
        }
    }
}
</script>