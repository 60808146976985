<template>
    <section class="">
        <div class="mb-4">
            <h2 class="text-4xl text-[#2b2b2b]">Horaire</h2>
        </div>
        <div class="">

            <ShiftsTableComponent />

            <div class="block-header">
                <h2>AJOUTER UN NOUVEAU SERVICE</h2>
            </div>

            <NewShiftComponent />

            <!-- LOADER -->
            <div class="page-loader-wrapper" v-if="loader">
                <div class="loader">
                    <div class="preloader">
                        <div class="spinner-layer pl-indigo">
                            <div class="circle-clipper left">
                                <div class="circle"></div>
                            </div>
                            <div class="circle-clipper right">
                                <div class="circle"></div>
                            </div>
                        </div>
                    </div>
                    <p>Chargement...</p>
                </div>
            </div>
            <!--  -->
        </div>
    </section>
</template>

<script>
import ShiftsTableComponent from '@/components/tables/ShiftsTable'
import NewShiftComponent from '@/components/create/NewShift'

export default {
    name: 'DepartementView',
    components: {
        NewShiftComponent,
        ShiftsTableComponent,
    },
    data() {
        return {
            loader: false,
        }
    },
    computed: {
        comp1() {
            return ""
        }
    },
    mounted() {
        this.loadpage()
    },
    created() {

    },
    methods: {
        loadpage() {
            this.loader = true
            var data = this
            setTimeout(() => {
                data.loader = false
            }, 500);
        }
    }
}

</script>