<template>
    <div class="mb-4 flex items-center justify-between">
            <h2 class="text-4xl text-[#2b2b2b]">Mon équipe</h2>
    </div>
    <div class="bg-white rounded-lg p-4 mb-3">
        <p class="mt-2 text-[#929292] font-extralight">
            Liste des collaborateurs enrégistré
        </p>
        <div class="mt-6">
            <table v-if="!isload" class="w-full">
                <thead class="w-full">
                    <tr class="text-black flex justify-between w-full py-3">
                        <th class="font-extralight w-[8%] pl-[13px]">Id</th>
                        <th class="font-extralight w-[28%] pl-[13px]">Nom & prenom</th>
                        <th class="font-extralight w-[15%] pl-[13px]">Contact</th>
                        <th class="font-extralight w-[13%] pl-[13px]">Status</th>
                        <th class="font-extralight w-[13%] pl-[13px]">Début</th>
                        <th class="font-extralight w-[13%] pl-[13px]">Fin</th>
                        <th class="font-extralight w-[10%] pl-[13px]">Action</th>
                    </tr>
                </thead>
                <tbody v-if="colabs.length != 0" class="w-full flex flex-col gap-y-2">
                    <tr class="text-black bg-[#f9fafb] font-extralight rounded-lg flex items-center justify-between w-full py-3" v-for="user in colabs" :key="user.id">
                        <td class="w-[8%] pl-[13px]">{{ user["id"] }}</td>
                        <td class="w-[28%] pl-[13px]">
                            <div class="flex flex-col items-start gap-y-2">
                                <span>{{ user["first_name"] + " " + user["last_name"] }}</span>
                                <span>{{ user["email"] }}</span>
                            </div>
                        </td>
                        <td class="w-[15%] pl-[13px]">{{ user["phone"] }}</td>
                        <td class="w-[13%] pl-[13px]">{{ user["status"] }}</td>
                        <td class="w-[13%] pl-[13px]">{{ user["date_start"] }}</td>
                        <td class="w-[13%] pl-[13px]">{{ user["date_end"] }}</td>
                        <td class="w-[10%] pl-[13px]">
                            <button v-on:click="pushDetails({
                                id : user['id'],
                                firstName : user['first_name'],
                                lastName : user['last_name']
                            })" class="px-4 py-2 rounded-lg text-white bg-[#5357eb]">Détails</button>
                        </td>
                    </tr>
                </tbody>
                <tr v-else class= "text-black bg-[#f9fafb] font-extralight rounded-lg flex justify-center w-full py-20">
                        Aucune donnée
                </tr>
            </table>
        </div>
    </div>
</template>
<script>
import API from "../../service/API.js"
import router from "@/router/index.js";
export default {
    name:"MyTeam",
    data(){
        return {
            isload : true,
            colabs:[]
        }
    },
    mounted(){
        this.getAllMember();
    },
    methods:{
        getAllMember(){
            const id = localStorage.getItem("userId") ?? this.$store.getters.userId;
            API.get("getUserTeam/"+id)
            .then((data)=>{
                this.colabs = data.data["data"];
            })
            .catch((error)=>{console.log(error)})
            .finally(()=>{this.isload = false;})
        },
        pushDetails(payload){
            const {id,firstName,lastName} = payload;
            router.push({path:"my-teams/"+firstName+" "+lastName+"/"+id});
        }
    }
}
</script>