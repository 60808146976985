<template>
    <div class="min-h-[60px] bg-white w-full border-b flex justify-between items-center px-4">
        <div class="h-[38px] border w-[35%] ml-6 rounded-md flex items-center">
            <span class="h-full pl-5 flex items-center">
                <Search :size="18" color="#99999a" stroke-width="1.5"/>
            </span>
            <input type="text" placeholder="Rechercher...." class="h-full grow outline-none border-none rounded-r-md p-5 text-black">
        </div>
        <div class="flex items-center gap-6">
            <span class="p-2 rounded-full border border-[#cdd3d7] cursor-pointer"><Bell color="#99999a" :size="18" stroke-width="1.5"/></span>
            <span class="p-2 rounded-full border border-[#cdd3d7] cursor-pointer"><MessageSquare color="#99999a" :size="18" stroke-width="1.5"/></span>
            <DropdownMenuRoot v-model:open="toggleState">
                <DropdownMenuTrigger
                    class="rounded-full w-[35px] h-[35px] inline-flex items-center justify-center text-grass11 bg-white shadow-[0_2px_10px] shadow-blackA7 outline-none hover:bg-green3 focus:shadow-[0_0_0_2px] focus:shadow-black"
                    aria-label="Customise options">
                    <button class="h-[35px] w-[35px] rounded-full border border-[#cdd3d7]
                        flex justify-center items-center">
                        <span class="text-[#5e5e5e] font-semibold">
                            {{firstNameIndex}}{{lastNameIndex}}
                        </span>
                    </button>
                </DropdownMenuTrigger>
                <DropdownMenuPortal>
                    <DropdownMenuContent
                        class="min-w-[220px] text-black outline-none bg-white rounded-md p-[5px] shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade"
                        :side-offset="5">
                        <DropdownMenuItem value="New Tab"
                            class="text-[14px] cursor-pointer hover:bg-[#f9fafb] leading-none rounded-md flex items-center justify-between p-4 select-none outline-none data-[disabled]:pointer-events-none"
                            v-on:click="logout"
                            >
                                <div class="flex items-center gap-x-2">
                                    <LucideLogOut :size="18" />
                                    <span>Déconnexion</span>
                                </div>
                            <div class="ml-auto pl-[20px] text-mauve11 group-data-[highlighted]:text-white">
                                ⌘+W
                            </div>
                        </DropdownMenuItem>
                    <DropdownMenuArrow class="fill-white" />
                </DropdownMenuContent>
                </DropdownMenuPortal>
            </DropdownMenuRoot>
        </div>
    </div>
</template>

<script>
import { Bell,MessageSquare,Search, LucideLogOut } from 'lucide-vue-next';
import Auth from "../../service/Auth.js";
import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuRoot,
  DropdownMenuTrigger,
  DropdownMenuArrow
} from 'radix-vue'
export default {
    name: 'AppBar',
    components: {
        Bell,
        MessageSquare,
        Search,
        LucideLogOut,
        DropdownMenuContent,
        DropdownMenuItem,
        DropdownMenuPortal,
        DropdownMenuRoot,
        DropdownMenuTrigger,
        DropdownMenuArrow
    },
    data(){
        return {
            toggleState:false
        }
    },
    methods: {
        logout(){
            Auth.logout();
        },
        openProfil(){
            this.$store.dispatch("switchShowProfil",true);
        }
    },
    computed:{
        firstNameIndex(){
            return this.$store.getters.firstName?.trim().charAt(0).toUpperCase();
        },
        lastNameIndex(){
            return this.$store.getters.lastName?.trim().charAt(0).toUpperCase();
        }
    }
}
</script>