import store from "../../../store";

class ToastMessage{
    constructor(type,title,message,duration){
        this.id = Date.now() + Math.floor(Math.random() * 100);
        this.type = type || "info";
        this.title = title;
        this.message = message;
        this.duration = duration || 3000
        this.timer = setTimeout(()=>{this.remove()},duration);
    }

    remove(){
        clearTimeout(this.timer);
        store.commit("remove",this);
    }
}

store.registerModule("toast", {
    state:()=>({
        messages:[]
    }),
    mutations:{
        push(state,payload){
            state.messages.push(payload);
        },
        remove(state,payload){
            const idx = state.messages.findIndex((msg)=> msg.id == payload.id);
            state.messages.splice(idx,1);
        }
    },
    actions:{
        remove(context,payload){
            context.commit("remove",payload);
        }
    },
});

const register = (status,title,message,duration) => 
    store.commit("push",new ToastMessage(status,title,message,duration));
const info = (title,message,duration) => register("info",title,message,duration);
const success = (title,message,duration) => register("success",title,message,duration);
const error = (title,message,duration) => register("error",title,message,duration);
const warning = (title,message,duration) => register("warning",title,message,duration);

export default {
    info,
    success,
    error,
    warning
};