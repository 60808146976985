<template>
    <main class="h-screen flex items-start">
    <sideBar />
    <div class="grow h-full flex flex-col">
      <appBar />
      <div class="grow w-full overflow-y-scroll p-8 bg-[#f9fafb]">
        <router-view/>
      </div>
    </div>
  </main>
</template>
<script>
import sideBar from '@/components/nav/side-bar.vue';
import appBar from '@/components/nav/app-bar.vue';

export default {
    components: {
      sideBar,
      appBar
    },
    created(){
      // this.$store.dispatch("tryLogin");
    }
  }
</script>