import $ from 'jquery'

export const initdatable = function (iditem) {
    setTimeout(() => {
        $(iditem).DataTable({
            responsive: true,
            dom: 'Blfrtip',
            buttons: [
                'csv', 'xlsx', 'pdf', 'print'
            ]
        })
    }, 0)
}
