export default{
    setErrors(state,payload){
        state.errors = payload;
        clearTimeout(state.idTimeoutShowError);
        state.idTimeoutShowError = setTimeout(() => {
            state.errors = {};
        }, 5000);
    },
    setMessages(state,payload){
        state.messages.push(payload);
        clearTimeout(state.idTimeoutShowMessage);
        state.idTimeoutShowMessage = setTimeout(() => {
            state.messages = [];
        }, 5000);
    }
}