import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";
export default {
    state(){
        return {
            messages: [],
            errors: {},
            idTimeoutShowMessage: null,
            idTimeoutShowError: null,
        }
    },
    mutations,
    actions,
    getters
}