import { createStore } from 'vuex'
import authModule from "./modules/auth/index.js";
import notifyModule from "./modules/notify/index.js";

export default createStore({
  modules: {
    auth: authModule,
    notify: notifyModule
  },
  state: {
    "showProfil": false,
    'connected': localStorage.getItem('connected') ?? false,
    'URL_API': 'https://attendance-backend.lce-ci.com/api/',
    'URL': 'https://attendance-backend.lce-ci.com/',
    'token': localStorage.getItem('token') ?? '',
    'auth_attendance': JSON.parse(localStorage.getItem('auth_attendance')) ?? null
  },
  getters: {
    showProfil(state){
        return state.showProfil
    }
  },
  mutations: {
    setShowProfil(state,payload){
        state.showProfil = payload
    }
  },
  actions: {
    switchShowProfil(context,payload){
        context.commit('setShowProfil',payload)
    }
  }
})
