export default{
    login(context,payload){
        localStorage.setItem('token',payload.token);
        localStorage.setItem('userId',payload.userId);
        localStorage.setItem("profil",payload.profil);
        localStorage.setItem("firstName",payload.firstName);
        localStorage.setItem("lastName",payload.lastName);
        localStorage.setItem("supId",payload.supId);
        context.commit('setUser',{
            token:payload.token,
            userid:payload.userId,
            profil:payload.profil,
            firstName:payload.firstName,
            lastName:payload.lastName,
            supId:payload.supId
        })
    },
    logout(context){
        localStorage.removeItem('token');
        localStorage.removeItem('userId');
        localStorage.removeItem('profil');
        localStorage.removeItem('firstName');
        localStorage.removeItem('lastName');
        localStorage.removeItem('supId');
        context.commit('setUser',{
            token:null,
            userId:null,
            profil:null,
            firstName:null,
            lastName:null,
            supId:null
        })
    },
    tryLogin(context){
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');
        const profil = localStorage.getItem('profil');
        const firstName = localStorage.getItem('firstName');
        const lastName = localStorage.getItem('lastName');
        const supId = localStorage.getItem('supId');
        if(token && userId && profil && firstName && lastName){
            context.commit('setUser',{
                token:token,
                userId:userId,
                profil:profil,
                firstName:firstName,
                lastName:lastName,
                supId:supId
            })
        }
    }
}