<template>
    <div class="flex items-center justify-between p-2 cursor-pointer" @click="expand">
        <span class="link-title text-2xl">Sécurité</span>
        <ChevronDown color="#707071" :size="18"/>
    </div>
    <div class="flex flex-col gap-y-2 px-2" v-if="isExpanded">
        <RouterLink to="/access" class="link-btn">
            <DoorOpen class="icon" :size="18"/>
            <span class="text-2xl text-[#606675]">Accès</span>
        </RouterLink>

        <RouterLink to="/zonesautorisees" class="link-btn">
            <MapPinned class="icon" :size="18"/>
            <span class="text-2xl text-[#606675]">Zone autorisées</span>
        </RouterLink>
    </div>
</template>
<script>
import { ChevronDown,DoorOpen,MapPinned } from 'lucide-vue-next';
import { RouterLink } from 'vue-router';
export default {
    name : "sideBarHead5",
    components:{
        ChevronDown,
        DoorOpen,
        MapPinned,
        RouterLink
    },
    props:[],
    data(){
        return {
            isExpanded : true
        }
    },
    methods:{
        expand(){
            this.isExpanded = !this.isExpanded;
        }
    }
}
</script>