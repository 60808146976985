<template>
    <DialogRoot>
        <DialogTrigger>
            <MessageSquarePlus :size="20" stroke-width="1.2" class="cursor-pointer"/>
        </DialogTrigger>
        <DialogPortal>
            <DialogOverlay className="fixed z-[1000] inset-0 bg-[#00000044] data-[state=open]:animate-overlayShow"/>
            <DialogContent className="fixed w-[45%] z-[1001] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-xl bg-white py-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none data-[state=open]:animate-contentShow">
                <DialogTitle class="m-0 text-[20px] font-normal px-[25px] text-black flex items-start justify-between">
                    Justifier une exception
                    <DialogClose>
                        <X :size="20" stroke-width="1.2" class="cursor-pointer"/>
                    </DialogClose>
                </DialogTitle>
                <DialogDescription class="text-black px-[25px] mt-4 text-[14px] font-extralight">
                    Les champs du formulaire suivis d'un <span class="text-[#f44545]">*</span> sont obligatoire. veuillez lire le formulaire
                    avant soummition
                </DialogDescription>
                <form @submit.prevent="submitForm" class="px-[25px] text-black mt-4">
                    <div class="w-full flex flex-col items-start ">
                        <label for="motif" class="font-extralight" >Motif de l'exception<span class="text-[#f44545]">*</span></label>
                        <select v-model="formdata.motif" name="motif" id="motif" class="h-[40px] w-full rounded-md border outline-none p-3 focus:outline-none bg-[#f9fafb] cursor-pointer">
                            <option value="">Choisissez le motif</option>
                            <option v-for="mtf in motifs" :key="mtf.id" :value="mtf.id"> 
                                {{mtf.text }} 
                            </option>
                        </select>
                    </div>
                    <div class="w-full flex flex-col items-start mt-2">
                        <label for="detail" class="font-extralight">Détails<span class="text-[#f44545]">*</span></label>
                        <textarea v-model="formdata.details" name="detail" id="" class="rounded-md bg-[#f9fafb] border p-5 h-[25vh] w-full"></textarea>
                    </div>
                    <div class="w-full flex justify-between mt-4 text-black">
                        <label for="pience" class="font-extralight">Pièce jointe</label>
                        <button v-on:click="clickFile" class="text-[#5357eb] flex items-center gap-x-3">
                            <Plus :size="20" stroke-width="1.2"/>
                            Choisir un fichier
                        </button>
                    </div>
                    <div class="w-full p-3 flex flex-wrap gap-3 items-start">

                        <div v-for="(file,idx) in files" :key="file.lastModifiedDate" class="h-20 w-1/5 border rounded p-2 flex items-start justify-between relative">
                            <div :class="getBgColor(file.type)" class="w-[25%] h-full flex items-center justify-center text-[12px] text-white rounded">
                                {{ getType(file.type) }}
                            </div>
                            <p class="text-[12px] w-[73%]">{{ formatText(file.name) }}</p>
                            <span v-on:click="removeFile(idx)" class="h-6 w-6 top-0 right-0 translate-x-[50%] translate-y-[-50%] cursor-pointer rounded-full absolute bg-red-500 flex items-center justify-center">
                                <X color="#fff" :size="12" />
                            </span>
                        </div>

                        <input type="file" v-on:change="selectFiles" name="filefield" multiple accept="image/*,.pdf" ref="file_input" class="hidden">
                    </div>
                    <div className="w-full mt-4 gap-x-3 flex items-center justify-end">
                            <DialogClose asChild><button :disabled="asSend" class="px-8 py-3 text-[#797e91] transition-colors hover:bg-[#e9e9ec] rounded-md">Cancel</button></DialogClose>
                                <button type="submit" :disabled="asSend" class="px-8 py-3 bg-[#5357ec] text-white rounded-md">
                                    <Loader2 v-if="asSend" class="animate-spin mx-auto"/>
                                    <span v-if="!asSend">Enregistrer</span>
                                </button>
                    </div>
                </form>
            </DialogContent>
        </DialogPortal>
    </DialogRoot>
</template>
<script>
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
  DialogTitle,
  DialogTrigger,
} from 'radix-vue';
import API from "@/service/API.js";
import toast from "../ui/toast";
import {
    MessageSquarePlus,
    X,
    Plus,
    Loader2
} from "lucide-vue-next"
export default {
    name:"JustifyException",
    components:{
        MessageSquarePlus,
        X,
        Loader2,
        DialogClose,
        DialogContent,
        Plus,
        DialogDescription,
        DialogOverlay,
        DialogPortal,
        DialogRoot,
        DialogTitle,
        DialogTrigger
    },
    props:["id_exception","id_type"],
    data(){
        return {
            asSend:false,
            motifs:[],
            files:[],
            formdata:{
                motif:"",
                details:"",
            }
        }
    },
    mounted(){
        this.getMotif();
    },
    methods:{
        jutifyException(){},
        resetForm(){
            this.formdata = {
                motif:"",
                details:"",
            }
        },
        getMotif(){
            API.get("/getTypesAndMotifs")
            .then((data)=>{
                this.motifs = data.data["motifs"].filter((el)=> el["is_leave"] != 1)
            })
            .catch(e=>{console.log(e)})
        },
        clickFile(){
            this.$refs.file_input.click();
        },
        selectFiles(e){
            if (!e.target.files?.[0]) return;
            this.files = [...e.target.files]
            console.log(e.target.files);
        },
        getType(type){
            const libelle = type.split("/")[0];
            console.log(libelle)
            return libelle == "image" ? "IMG" : "DOC"
        },
        getBgColor(type){
            return this.getType(type) == "IMG" ? "bg-[#5357eb]": "bg-red-500"
        },
        formatText(libelle){
            return libelle.length >= 21 ? libelle.substring(0,20) + "..." : libelle;
        },
        removeFile(index){
            const tab = [...this.files];
            tab.splice(index,1);
            this.files = [...tab];
        },
        submitForm(){
            this.asSend = true;
            const formData = new FormData();
            formData.append("exception_id",this.id_exception);
            formData.append("motif_id",this.formdata.motif);
            formData.append("other_motif",this.formdata.details);
            if(this.files.length != 0){
                formData.append("file",this.files[0]);
            }
            API.post("askForJustification",formData)
            .then((response)=>{
                if(response.data["status"]){
                    toast.success("Succes","Exception justifié",3000)
                }else{
                    toast.error("Echèc",response.data["message"],3000)
                    this.resetForm();
                }
            })
            .catch()
            .finally(()=>{
                this.asSend = false;
            })
        }
    }
}
</script>