<template>
    <div class="flex items-center justify-between p-2 cursor-pointer" @click="expand">
        <span class="link-title text-2xl">Dashboard</span>
            <ChevronDown color="#707071" :size="18"/>
    </div>
    <div class="flex flex-col gap-y-2 px-2" v-if="isExpanded">
        <RouterLink to="/" class="link-btn">
            <LayoutGrid class="icon" :size="18"/>
            <span class="text-2xl text-[#606675]">Accueil</span>
        </RouterLink>
    </div>
</template>
<script>
import { ChevronDown,LayoutGrid } from 'lucide-vue-next';
import { RouterLink } from 'vue-router';
export default {
    name : "SideBarHeader1",
    components:{
        ChevronDown,
        LayoutGrid,
        RouterLink
    },
    props:[],
    data(){
        return {
            isExpanded : true
        }
    },
    methods:{
        expand(){
            this.isExpanded = !this.isExpanded;
        }
    }
}
</script>